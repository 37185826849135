const jobs = [
    {
        id: 1,
        order: 3,
        title: "Bartender",
        dates: "December 2017 - Present",
        duties: ["Train, supervise, and work with bartending staff to uphold service standards, food quality, drink quality, and customer satisfaction." , 
            "Maintain cocktail design, inventory, and high-quality customer relationships.", 
            "Anticipate customers needs in a fast-paced environment where multi-tasking is a must."
                ],
        company: "Two Birds Taphouse",
    },
    {
        id: 2,
        order: 2,
        title: "Server",
        dates: "June 2013 - August 2017",
        duties: ["Provide customers with best dining experience possible." , 
        "Maintain a clean and effective work environment for fellow employees to work in.", 
        "High-volume work, multi-tasking and results a must."
                ],
        company: "Marietta Pizza Company",
    },
    {
        id: 3,
        order: 1,
        title: "Airplane Mechanic",
        dates: "December 2010 - January 2012",
        duties: ["Build, install, and customize the mechanics and structures of the aircraft assembly per blueprint in a high-performance environment." , 
        "Follow OSHA and various regulatory standards and safety practices and procedures.", 
        "Keeping a clean working environment, meeting deadlines, all while following ethical standards to produce a quality aircraft."],
        company: "Lockheed Martin",
    },

];

export default jobs
