import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
import Layout  from "../components/Layout"
import Work from "../components/Jobs"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const Resume = ({
  data: {
    allContentfulAbout: { nodes },
  },
}) => {
const {title, stack, info, image} = nodes[0]
  
  
  return (
    <Layout>
      <Seo title="Résumé" description="My Résumé Page" />
      <section className="about-page">
     
        <div className="section-center about-center">
        <GatsbyImage 
            image={image.gatsbyImageData} 
            alt='hero' 
            className="about-img"
        />
        
          {/* Tech Stack */}
          <article className="about-text">
          <Title title="Résumé" />
          <p>I am currently in the process of putting together a detailed Résumé online. In the meantime, I have attached a PDF copy of my resume below.</p>
          <a href="https://assets.ctfassets.net/9e6qzia9m5h5/1dNxI0OCWVTndni5B4HCxR/e3543378f379ff5f74ed67829d33306a/KennyBates_Resume_2021.pdf" className="btn">
            Résumé PDF
          </a>
          <p></p>
          <div className="about-stack">
              {
                stack.tags.map((item, index) => {
                  return (
                    <span key={item.index}>{item}</span>
                  )
                })
              }
        </div>
        </article>
        </div>
      </section>
      
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulAbout {
      nodes {
        title
        info {
          internal {
            content
          }
        }
        image {
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, height: 400, width: 400)
        }
        stack {
          tags
        }
      }
    }
  }
`

export default Resume
