import React from "react"
import {useState, useEffect} from 'react';
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import data from '../constants/jobs'



const Jobs = () => {
  // State values
  const [jobs, setJobs] = useState(data);
  const [value, setValue] = useState(0);

  //Deconstruct jobs
  const { company, dates, duties, title } = jobs[value];

  return (
    <section className='section jobs'>
     <Title title="work experience" />
     <div className='jobs-center'>
       {/* btn container */}
        <div className='btn-container'>
          {
            jobs.map((job, index) => {
              return (
                <button 
                  key={job.id} 
                  onClick={()=> setValue(index)}
                  className={`job-btn ${index === value && 'active-btn'}`}
                  > 
                  {job.company}
                </button>
              );
            })
          }
            
        </div>
       {/* jobs info */}
       <article className='job-info'>
         <h3 className='title'>{title}</h3>
         <h4>{company}</h4>
         <p className='job-date'>{dates}</p>
         {duties.map((duty, index) => {
           return (
            <div key={index} className='job-desc'>
              <FaAngleDoubleRight className='job-icon'/>
              <p>{duty}</p>
            </div>
           );
         }
         )}
       </article>
     </div>
     <Link to="/about" className="btn center-btn" >
       more info
     </Link>
   </section>
         
  )
}

export default Jobs
